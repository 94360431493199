import type { ChartResolverProps } from '../ChartResolver'
import { CustomResponsiveContainer } from '../CustomResponsiveContainer'
import { CHART_SIZE, CHART_TYPES, DEFAULT_CHART_OPTIONS } from '../consts'
import type { ChartType } from '../types'
import { getChartKeys, getYAxisDomain } from '../utils'
import { CustomTooltip } from './CustomTooltip'
import styles from './chart.module.styl'
import { renderAreas, renderBars, renderLines } from './chartRenderers'
import classnames from 'classnames'
import { Brush, CartesianGrid, ComposedChart, Label, Legend, Tooltip, XAxis, YAxis } from 'recharts'

/**
 * AxesChart should be used for cartesian types of charts like Area, Bar and Line
 */
export const AxesChart = ({
  type,
  height,
  additionalClassName = '',
  options,
  size,
  isExpanded = false,
  colorPalette,
  ...props
}: ChartResolverProps): JSX.Element | null => {
  if (!props.data || Object.keys(props.data).length === 0) {
    return null
  }

  const keys = getChartKeys(props.data, ['drillDownUrl'])

  const renderChart = (type: ChartType) => {
    switch (type) {
      case CHART_TYPES.AreaChart:
        return renderAreas(keys, 'regular', colorPalette)
      case CHART_TYPES.StackedAreaChart:
        return renderAreas(keys, 'stacked', colorPalette)
      case CHART_TYPES.BarChart:
        return renderBars(keys, 'regular', colorPalette, props.data)
      case CHART_TYPES.StackedBarChart:
        return renderBars(keys, 'stacked', colorPalette, props.data)
      case CHART_TYPES.LineChart:
        return renderLines(keys, colorPalette)
      default:
        return null
    }
  }

  return (
    <div
      className={classnames(styles.chart, additionalClassName, {
        [styles.small]: size === CHART_SIZE.small,
        [styles.large]: size === CHART_SIZE.large,
      })}
      style={height ? { height } : {}}
    >
      <CustomResponsiveContainer {...props} width='99%' height={height || '99%'}>
        <ComposedChart {...props}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='name'
            height={46}
            unit={options?.xAxisUnit}
            stroke={DEFAULT_CHART_OPTIONS.axisStrokeColor}
          >
            <Label
              value={options?.xAxisLabel}
              position='insideTopRight'
              fontWeight={600}
              dx={5}
              dy={18}
            />
          </XAxis>
          <YAxis
            unit={options?.yAxisUnit}
            stroke={DEFAULT_CHART_OPTIONS.axisStrokeColor}
            domain={getYAxisDomain(options?.axisYLimit)}
            allowDataOverflow
          >
            <Label value={options?.yAxisLabel} angle={-90} position='insideLeft' fontWeight={600} />
          </YAxis>
          <Tooltip content={<CustomTooltip isExpanded={isExpanded} />} />
          {isExpanded && options?.legend && (
            <Legend
              key='legend'
              verticalAlign='bottom'
              align='center'
              height={50}
              wrapperStyle={{ paddingTop: '12px' }}
            />
          )}
          {isExpanded && options?.withBrush && <Brush key='brush' height={20} dy={0} />}
          {renderChart(type)}
        </ComposedChart>
      </CustomResponsiveContainer>
    </div>
  )
}
