type ApiHandler = (options: { accessToken: string }) => Promise<unknown>

export type WidgetApisConfig = Record<string, ApiHandler>

export const getWidgetApiHandler = (apis: WidgetApisConfig) => (sourcePath: string) => {
  const handlerName = sourcePath.split('cx/')[1]
  if (!handlerName) {
    throw new Error('Incorrect source path or name')
  }
  const handler = apis[handlerName]
  if (!handler) {
    throw new Error('widget data api not found')
  }
  return handler
}
