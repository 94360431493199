export const parseQueryParam = (queryValue: string | null, defaultValue: number | string) => {
  if (!queryValue) return defaultValue
  try {
    return eval?.('`"use strict";' + queryValue + '`').replace('"use strict";', '')
  } catch (error) {
    console.error(error)
  }

  return defaultValue
}
