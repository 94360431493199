import styles from '../../widget.module.styl'
import { CustomFields } from './types'
import { Button, ButtonGroup, SidePanel, Text, TextField } from '@nike/eds'
import { Control, Controller } from 'react-hook-form'

export interface SaveWidgetPanelProps {
  control: Control<CustomFields>
  closeSidePanel: () => void
  saveAsExisting: () => void
  saveAsNew: () => void
}

export const SaveWidgetPanel = ({
  closeSidePanel,
  saveAsExisting,
  saveAsNew,
  control,
}: SaveWidgetPanelProps) => (
  <SidePanel
    isOpen
    hasScrim
    onDismiss={closeSidePanel}
    headerSlot='Save as...'
    footerSlot={
      <ButtonGroup>
        <Button size='small' onClick={closeSidePanel}>
          Close
        </Button>
        <Button size='small' variant='secondary' onClick={saveAsNew}>
          Save as New
        </Button>
        <Button size='small' variant='secondary' onClick={saveAsExisting}>
          Save as Existing
        </Button>
      </ButtonGroup>
    }
  >
    <div>
      <Text font='title-6' className={styles.dataSourceURLTitle}>
        Widget name
      </Text>{' '}
      <Controller
        name='name'
        control={control}
        render={({ field }) => (
          <TextField
            id='name'
            placeholder='name'
            label='parameter'
            hideLabel
            data-testid='widget-name'
            {...field}
          />
        )}
      />
    </div>
  </SidePanel>
)
