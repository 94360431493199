import styles from '../widget.module.styl'
import { Tag } from '@nike/eds'
import { ReactNode } from 'react'

interface TagsProps {
  tags?: (string | null)[]
  id?: string
  children?: ReactNode
}

export const Tags = ({ tags = [], id, children }: TagsProps): JSX.Element => (
  <div className={styles.tags} id={id}>
    {children}
    {tags.map(
      (tag, index) =>
        tag && (
          <Tag size='small' key={`${tag}-${index}`}>
            {tag}
          </Tag>
        )
    )}
  </div>
)
