import { ColorPalette, SnackOptions, Widget } from '../../types'
import { getWidgetUrl } from '../../utils/getWidgetUrl'
import styles from '../../widget.module.styl'
import { ChartResolver, ChartData, ChartOptions } from '../Charts'
import { WidgetMessage } from '../WidgetMessage/WidgetMessage'
import { Button, ButtonGroup, Icon, Modal, Spinner, Text } from '@nike/eds'

export interface WidgetModalProps<T> {
  widget: T & { isFilteredOut?: boolean }
  widgetData?: ChartData
  isShareable?: boolean
  isFetching?: boolean
  errorMessage?: string
  closeModal: () => void
  createSnack?: (options: SnackOptions) => void
  additionalButtons?: JSX.Element
  chartOptions?: ChartOptions
}

export const WidgetModal = <T extends Widget>({
  closeModal,
  widget,
  widgetData,
  createSnack,
  isShareable,
  isFetching = false,
  errorMessage,
  additionalButtons,
  chartOptions,
}: WidgetModalProps<T>) => {
  const handleClickOnCopyURL = async () => {
    widget && (await navigator.clipboard.writeText(getWidgetUrl(widget.id || widget.name)))
    createSnack?.({
      id: 'widget-link-copied',
      status: 'success',
      message: 'Link copied to clipboard',
    })
  }

  return (
    <Modal
      isOpen
      displayCheckOverride={process.env.NODE_ENV === 'test'}
      onDismiss={closeModal}
      className={styles.widgetDetailsModal}
      headerSlot={widget.name}
      data-testid='expanded-widget'
      footerSlot={
        <ButtonGroup>
          <Button size='small' onClick={closeModal}>
            Close
          </Button>
          {additionalButtons}
          {isShareable && !!createSnack && !widget.customWidgetId && (
            <Button
              size='small'
              variant='secondary'
              beforeSlot={<Icon name='Link' />}
              onClick={handleClickOnCopyURL}
            >
              Copy Link
            </Button>
          )}
        </ButtonGroup>
      }
      hideFade
    >
      <>
        <Text font='body-3' className='eds-spacing--mb-32'>
          {widget.description}
        </Text>
        {isFetching && (
          <div className={styles.spinner}>
            <Spinner role='progressbar' />
          </div>
        )}
        {errorMessage && <WidgetMessage text={errorMessage} />}
        {widgetData && (
          <ChartResolver
            type={widget.chartType || widgetData.value.type}
            data={widgetData.value?.data}
            columns={widgetData.value?.columns}
            responsiveHeight='100%'
            additionalClassName={styles.chart}
            size='large'
            isExpanded
            colorPalette={widget?.colorPalette || ColorPalette.CATEGORICAL}
            options={chartOptions}
          />
        )}
      </>
    </Modal>
  )
}
