// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customResponsiveContainer-module__customResponsiveWrapper--AU4yr{width:100%;height:100%;position:relative}.customResponsiveContainer-module__customResponsiveWrapper--AU4yr .customResponsiveContainer-module__customResponsiveInner--r1zWS{width:100%;height:100%;position:absolute;top:0;left:0}`, "",{"version":3,"sources":["webpack://./src/components/Charts/customResponsiveContainer.module.styl"],"names":[],"mappings":"AAAA,kEACE,UAAM,CACN,WAAO,CACP,iBAAS,CAET,kIACE,UAAM,CACN,WAAO,CACP,iBAAS,CACT,KAAI,CACJ,MAAK","sourcesContent":[".customResponsiveWrapper\n  width 100%\n  height 100%\n  position relative\n\n  .customResponsiveInner\n    width 100%\n    height 100%\n    position absolute\n    top 0\n    left 0"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customResponsiveWrapper": `customResponsiveContainer-module__customResponsiveWrapper--AU4yr`,
	"customResponsiveInner": `customResponsiveContainer-module__customResponsiveInner--r1zWS`
};
export default ___CSS_LOADER_EXPORT___;
