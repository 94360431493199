// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radarChart-module__chart--ZLSoY{font-size:10px}.radarChart-module__chart--ZLSoY.radarChart-module__small--P6B2V{font-size:10px}.radarChart-module__chart--ZLSoY.radarChart-module__large--laoni{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/Charts/RadarChart/radarChart.module.styl"],"names":[],"mappings":"AAAA,iCACE,cAAU,CACV,iEACE,cAAU,CACZ,iEACE,cAAU","sourcesContent":[".chart\n  font-size 10px\n  &.small\n    font-size 10px\n  &.large\n    font-size 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `radarChart-module__chart--ZLSoY`,
	"small": `radarChart-module__small--P6B2V`,
	"large": `radarChart-module__large--laoni`
};
export default ___CSS_LOADER_EXPORT___;
