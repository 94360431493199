// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Collapsible-module__subtitle--aXIOg{margin-top:6px}.Collapsible-module__subtitle--aXIOg span{color:var(--eds-color-grey-7)}.Collapsible-module__trigger--mjjU8{display:flex}.Collapsible-module__triggerIcon--tSbQQ{padding:0 17px}.Collapsible-module__collapsibleInner--OqwIl{margin:0 60px 24px}`, "",{"version":3,"sources":["webpack://./src/components/Collapsible/Collapsible.module.styl"],"names":[],"mappings":"AAGA,qCACE,cAAW,CAEX,0CACE,6BAAM,CAEV,oCACE,YAAQ,CAEV,wCACE,cAAQ,CAEV,6CACE,kBAAO","sourcesContent":[".title\n  span\n\n.subtitle\n  margin-top 6px\n\n  span\n    color var(--eds-color-grey-7)\n\n.trigger\n  display flex\n\n.triggerIcon\n  padding 0 17px\n\n.collapsibleInner\n  margin 0 60px 24px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"subtitle": `Collapsible-module__subtitle--aXIOg`,
	"trigger": `Collapsible-module__trigger--mjjU8`,
	"triggerIcon": `Collapsible-module__triggerIcon--tSbQQ`,
	"collapsibleInner": `Collapsible-module__collapsibleInner--OqwIl`
};
export default ___CSS_LOADER_EXPORT___;
