// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetMessage-module__text--Iwbue{max-width:100%;min-height:160px;color:var(--eds-color-grey-1);text-align:center;margin:var(--eds-space-32) 0;flex:1;align-self:center;display:flex;flex-flow:column;justify-content:center;word-break:break-all}.widgetMessage-module__text--Iwbue svg{display:block;margin:0 auto 8px}`, "",{"version":3,"sources":["webpack://./src/components/WidgetMessage/widgetMessage.module.styl"],"names":[],"mappings":"AAAA,mCACE,cAAU,CACV,gBAAW,CACX,6BAAM,CACN,iBAAW,CACX,4BAAO,CACP,MAAK,CACL,iBAAW,CACX,YAAQ,CACR,gBAAU,CACV,sBAAgB,CAChB,oBAAW,CAEX,uCACE,aAAQ,CACR,iBAAO","sourcesContent":[".text\n  max-width 100%\n  min-height 160px\n  color var(--eds-color-grey-1)\n  text-align center\n  margin var(--eds-space-32) 0\n  flex 1\n  align-self center\n  display flex\n  flex-flow column\n  justify-content center\n  word-break break-all\n\n  svg\n    display block\n    margin 0 auto 8px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `widgetMessage-module__text--Iwbue`
};
export default ___CSS_LOADER_EXPORT___;
