import chartStyles from '../charts.module.styl'
import type { ChartSize, DataItem, EntryDetails } from '../types'
import { ItemWithDetails } from './ItemWithDetails'
import styles from './listChart.module.styl'
import { Link, Text } from '@nike/eds'
import classnames from 'classnames'

interface ListChartProps {
  data?: DataItem[]
  size?: ChartSize
}

export type ListChartItem = {
  name: string
  drillDownUrl?: string
  details?: EntryDetails[]
}

const isListItem = (item: DataItem): item is ListChartItem => typeof item?.name === 'string'

export const ListChart = ({ data, size }: ListChartProps): JSX.Element => (
  <div className={classnames(styles.listChart, chartStyles.scrollable)}>
    <ul className={styles.list}>
      {data?.filter(isListItem).map((entry, index) =>
        entry?.details ? (
          <ItemWithDetails item={entry} size={size} key={`${entry.name}-${index}`} />
        ) : (
          <Text<'li'>
            as='li'
            key={`${entry.name}-${index}`}
            className={styles.listItem}
            font={size === 'small' ? 'subtitle-2' : 'subtitle-1'}
          >
            {entry?.drillDownUrl ? (
              <Link target='_blank' href={entry.drillDownUrl}>
                {entry.name}
              </Link>
            ) : (
              entry.name
            )}
          </Text>
        )
      )}
    </ul>
  </div>
)
