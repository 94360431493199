export const GITHUB_HOSTS = {
  github_com: 'github.com',
  raw_githubusercontent_com: 'raw.githubusercontent.com',
  api_github_com: 'api.github.com',
}

const VALID_GITHUB_HOSTS = [
  GITHUB_HOSTS.github_com,
  GITHUB_HOSTS.raw_githubusercontent_com,
  GITHUB_HOSTS.api_github_com,
]

export const isValidURL = (url: string) => {
  if (!url) {
    return false
  }
  const expression =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi
  const regex = new RegExp(expression)

  return !!url.match(regex)
}

export const isGithubUrlValid = (url: string): boolean => {
  if (!isValidURL(url)) {
    return false
  }
  const { hostname } = new URL(url)
  return hostname ? VALID_GITHUB_HOSTS.includes(hostname) : false
}
