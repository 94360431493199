import styles from './widgetMessage.module.styl'
import { Icon, Text } from '@nike/eds'
import classnames from 'classnames'

interface WidgetMessageProps {
  text: string
  icon?: JSX.Element
  additionalClassName?: string
}

export const WidgetMessage = ({
  additionalClassName,
  icon,
  text,
}: WidgetMessageProps): JSX.Element => (
  <Text<'div'> font='body-3' as='div' className={classnames(styles.text, additionalClassName)}>
    {icon ? icon : <Icon name='AlertCircle' size='m' />}
    {text}
  </Text>
)
