import type { ChartResolverProps } from '../ChartResolver'
import { CustomResponsiveContainer } from '../CustomResponsiveContainer'
import { CHART_SIZE, DEFAULT_CHART_OPTIONS } from '../consts'
import { areAllValuesZero, getChartKeys, getColor, is2DArray, openInNewTab } from '../utils'
import styles from './pieChart.module.styl'
import classnames from 'classnames'
import { useCallback } from 'react'
import { Cell, Legend, Pie, PieChart as PieChartRecharts, Text, Tooltip } from 'recharts'

interface CustomizedLabelProps {
  cx: number
  cy: number
  midAngle: number
  innerRadius: number
  outerRadius: number
  percent: number
}

const RADIAN = Math.PI / 180
const INNER_PIE_START_SIZE = 20
const MAX_PIES_WIDTH_SPACE = 100

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}: CustomizedLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 1.1
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <Text
      x={x}
      y={y}
      fill='black'
      style={{ fontSize: '1em' }}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline='central'
    >
      {`${percent.toFixed(0)}%`}
    </Text>
  )
}

export const PieChart = ({
  colorPalette,
  height,
  options,
  isExpanded = false,
  ...props
}: ChartResolverProps) => {
  // eslint-disable-next-line
  const sortData = useCallback((data: any[], key: string) => {
    // need to be any here
    const total = data.map((item) => item[key]).reduce((total, amount) => total + amount)
    data = data.map((item) => ({ ...item, percent: (item[key] / total) * 100 }))

    // eslint-disable-next-line
    const sorter = (a: any, b: any) => {
      // need to be any here
      if (a[key] < b[key]) {
        return -1
      } else if (a[key] > b[key]) {
        return 1
      }

      return 0
    }

    return data.sort(sorter)
  }, [])

  if (!props?.data || !Object.keys(props?.data).length) {
    return null
  }

  let pies = null

  if (is2DArray(props.data)) {
    const dataLength = props.data.length || 0
    const segment = (MAX_PIES_WIDTH_SPACE - INNER_PIE_START_SIZE) / dataLength // pie width with space
    const space = segment / dataLength // width of space between each pies
    let radius = segment

    pies =
      props.data &&
      props.data.map((singleData, index) => {
        const keys = getChartKeys(singleData)

        if (!keys.length) {
          return null
        }

        const sortedData = sortData(singleData, keys[0])
        const attributes = {
          innerRadius: `${index === 0 ? 0 : radius}%`,
          outerRadius: `${
            index === 0 ? radius + INNER_PIE_START_SIZE - space : radius + segment - space
          }%`,
        }

        radius = index === 0 ? radius + INNER_PIE_START_SIZE : radius + segment

        return (
          <Pie
            key={'dataSet' + index}
            label={index >= dataLength - 1 && renderCustomizedLabel}
            labelLine={false}
            nameKey='name'
            cx='50%'
            cy='50%'
            data={sortedData}
            dataKey={keys[0]}
            isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
            {...attributes}
          >
            {sortedData.map(
              (
                // eslint-disable-next-line
                entry: any, // need to be any here
                index: number
              ) => (
                <Cell
                  key={entry.name}
                  fill={getColor(index, colorPalette)}
                  onClick={() => entry.drillDownUrl && openInNewTab(entry.drillDownUrl)}
                />
              )
            )}
          </Pie>
        )
      })
  } else {
    const keys = getChartKeys(props.data)

    if (!keys.length) {
      return null
    }

    const sortedData = sortData(props.data, keys[0])
    pies = (
      <Pie
        key='dataSet'
        nameKey='name'
        cx='50%'
        cy='50%'
        label={renderCustomizedLabel}
        labelLine={false}
        data={sortedData}
        dataKey={keys[0]}
        outerRadius='80%'
        fill={getColor(0, colorPalette)}
        isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
      >
        {sortedData.map(
          (
            // eslint-disable-next-line
            entry: any, // need to be any here
            index: number
          ) => (
            <Cell
              key={entry.name}
              fill={getColor(index, colorPalette)}
              onClick={() => entry.drillDownUrl && openInNewTab(entry.drillDownUrl)}
            />
          )
        )}
      </Pie>
    )
  }

  const emptyPieLegend = (
    <Legend
      key='legend'
      verticalAlign='bottom'
      align='center'
      height={50}
      payload={props.data.flat().map((item) => ({
        value: `${item.name}: 0`,
        color: '#b2b2b2',
        type: 'square',
        id: item.name,
      }))}
      wrapperStyle={{
        overflow: 'auto',
      }}
    />
  )

  const emptyPie = (
    <Pie
      key='dataSet'
      nameKey='name'
      cx='50%'
      cy='50%'
      labelLine={false}
      stroke='none'
      fill='#b2b2b2'
      isAnimationActive={false}
      dataKey='value'
      data={[{ name: 'No Data', value: 1 }]}
    />
  )

  return (
    <div
      className={classnames(styles.chart, props.additionalClassName, {
        [styles.small]: props.size === CHART_SIZE.small,
        [styles.large]: props.size === CHART_SIZE.large,
      })}
      style={height ? { height } : {}}
    >
      <CustomResponsiveContainer width='99%' height={height || '99%'}>
        {areAllValuesZero(props.data) ? (
          <PieChartRecharts {...props}>
            {emptyPieLegend}
            {emptyPie}
          </PieChartRecharts>
        ) : (
          <PieChartRecharts {...props}>
            <Tooltip offset={20} cursor={{ stroke: 'red', strokeWidth: 2 }} />
            {isExpanded && options?.legend && (
              <Legend key='legend' verticalAlign='bottom' align='center' height={50} />
            )}
            {pies}
          </PieChartRecharts>
        )}
      </CustomResponsiveContainer>
    </div>
  )
}
