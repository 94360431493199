import { labels } from '../../constants'
import { SnackOptions } from '../../types'
import { getWidgetUrl } from '../../utils/getWidgetUrl'
import { ResizeWidget } from './ResizeWidget'
import { MenuType, WidgetMenu } from './WidgetMenu'
import { Link, MenuItem } from '@nike/eds'
import { Fragment } from 'react'

type AdditionalPropType = string | number | boolean | JSX.Element | object | null | undefined

export interface WidgetMenuItemConfig {
  type: MenuType
  classNames?: string
  onClick?: (...args: any) => void
  additionalProps?: { [key: string]: AdditionalPropType }
  content?: string
  isInFooter?: boolean
  contextualComponentProps?: { [key: string]: AdditionalPropType } // props for component rendered on menu item click
}

interface WidgetMenuWrapperProps {
  config: WidgetMenuItemConfig[]
  isMenuOpen: boolean
  id: string
  toggleMenu: () => void
  createSnack?: (options: SnackOptions) => void
}

export const WidgetMenuResolver = ({
  isMenuOpen,
  toggleMenu,
  config = [],
  id,
  createSnack,
}: WidgetMenuWrapperProps) => {
  const supportedMenuItems = {
    [MenuType.BASIC]: ({ onClick, content }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
      >
        {content}
      </MenuItem>
    ),
    [MenuType.SHARE]: () => (
      <MenuItem
        onClick={async () => {
          const widgetUrl = getWidgetUrl(id || '')
          await navigator.clipboard.writeText(widgetUrl)
          createSnack?.({
            id: 'widget-link-copied',
            status: 'success',
            message: 'Link copied to clipboard',
          })
          toggleMenu()
        }}
      >
        {labels.menu.share}
      </MenuItem>
    ),
    [MenuType.LINK]: ({ additionalProps, content, isInFooter }: WidgetMenuItemConfig) =>
      isInFooter ? (
        <div>
          <Link target='_blank' {...additionalProps}>
            {content}
          </Link>
        </div>
      ) : (
        <MenuItem>
          <Link target='_blank' {...additionalProps}>
            {content}
          </Link>
        </MenuItem>
      ),

    [MenuType.EXPAND]: ({ onClick }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
      >
        {labels.menu.expand}
      </MenuItem>
    ),
    [MenuType.REFRESH]: ({ onClick }: WidgetMenuItemConfig) => (
      <MenuItem
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
      >
        {labels.menu.refresh}
      </MenuItem>
    ),
    [MenuType.RESIZE]: ({ onClick, additionalProps }: WidgetMenuItemConfig) => (
      <ResizeWidget
        handleResizeWidget={onClick}
        toggleMenu={toggleMenu}
        id={id}
        {...additionalProps}
      />
    ),
    [MenuType.REMOVE]: ({
      classNames,
      onClick,
      additionalProps,
    }: Partial<WidgetMenuItemConfig>) => (
      <MenuItem className={classNames} onClick={onClick} {...additionalProps}>
        {labels.menu.remove}
      </MenuItem>
    ),
    [MenuType.FAVORITE]: ({
      classNames,
      onClick,
      additionalProps,
      content,
    }: Partial<WidgetMenuItemConfig>) => (
      <MenuItem className={classNames} onClick={onClick} {...additionalProps}>
        {content}
      </MenuItem>
    ),
    [MenuType.EDIT_QUERY]: ({
      classNames,
      onClick,
      additionalProps,
    }: Partial<WidgetMenuItemConfig>) => (
      <MenuItem
        className={classNames}
        onClick={() => {
          onClick?.()
          toggleMenu()
        }}
        {...additionalProps}
      >
        {labels.menu.editQuery}
      </MenuItem>
    ),
  }

  const bodySlotContent = () =>
    config
      .filter(({ isInFooter }: WidgetMenuItemConfig) => !isInFooter)
      .map(
        ({ type, ...rest }: WidgetMenuItemConfig, index: number) =>
          type && <Fragment key={index}>{supportedMenuItems[type]({ type, ...rest })}</Fragment>
      )

  const footerSlotContent = () =>
    config
      .filter(({ isInFooter }: WidgetMenuItemConfig) => isInFooter)
      .map(
        ({ type, ...rest }: WidgetMenuItemConfig, index: number) =>
          type && <Fragment key={index}>{supportedMenuItems[type]({ type, ...rest })}</Fragment>
      )

  return (
    <WidgetMenu
      isMenuOpen={isMenuOpen}
      toggleMenu={toggleMenu}
      bodySlotContent={bodySlotContent}
      footerSlotContent={footerSlotContent}
    />
  )
}
