import { SnackOptions, SupportedParam, Widget } from '../../types'
import { CustomFields, EditQueryProps, QueryParamsRow } from './types'

export enum CUSTOM_FIELDS {
  name = 'name',
  queryParams = 'queryParams',
  supportedRequiredParams = 'supportedRequiredParams',
}

export const hasSaveWidgetHandler = (props?: Record<string, any>): props is EditQueryProps =>
  !!props?.handleSaveWidget

export const createSourceURLWithParams = (queryParams: QueryParamsRow[], url?: string) => {
  const reduceSourceURL = queryParams.reduce(
    (total: string, next: QueryParamsRow, nextIndex: number) =>
      total +
      `${nextIndex === 0 ? '?' : ''}${next.parameter}${
        next.parameter && next.value.trim() ? `=${next.value.trim()}` : ''
      }${nextIndex < queryParams.length - 1 && next.parameter ? '&' : ''}`,
    url || ''
  )

  // remove last ? or & from url
  const updatedSourceURL =
    reduceSourceURL.slice(-1) === '?' || reduceSourceURL.slice(-1) === '&'
      ? reduceSourceURL.slice(0, -1)
      : reduceSourceURL

  return updatedSourceURL
}

export const copyURL = async (url: string, createSnack?: (options: SnackOptions) => void) => {
  await navigator.clipboard.writeText(url)
  createSnack?.({
    id: 'widget-data-url-copied',
    status: 'success',
    message: 'Data Source URL copied to clipboard',
  })
}

export const generateQueryParamsOptions = (params: SupportedParam[]) =>
  [...new Set(params.map((item: SupportedParam) => item.parameter))].map((param) => ({
    label: param || '',
    value: param || '',
  }))

export const mapInboundDataToFormValues = (widget: Widget): CustomFields => {
  const { name, queryParams, supportedParams } = widget

  const filteredQueryParams = queryParams?.filter((queryParam) =>
    supportedParams
      ?.filter(({ isRequired }) => !!isRequired)
      .every(({ parameter }) => parameter !== queryParam.parameter)
  )

  const supportedRequiredParams = (supportedParams || [])
    .filter(({ isRequired }) => !!isRequired)
    .map(({ parameter, defaultValue }) => ({
      parameter: parameter || '',
      value:
        queryParams?.find((duplicate) => parameter === duplicate.parameter)?.value ||
        defaultValue ||
        '',
    }))

  return {
    name,
    queryParams: filteredQueryParams || [],
    supportedRequiredParams,
  }
}
