// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pieChart-module__chart--GZdif{font-size:10px}.pieChart-module__chart--GZdif.pieChart-module__small--C5MkR{font-size:10px}.pieChart-module__chart--GZdif.pieChart-module__large--sa9NR{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/Charts/PieChart/pieChart.module.styl"],"names":[],"mappings":"AAAA,+BACE,cAAU,CACV,6DACE,cAAU,CACZ,6DACE,cAAU","sourcesContent":[".chart\n  font-size 10px\n  &.small\n    font-size 10px\n  &.large\n    font-size 14px"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `pieChart-module__chart--GZdif`,
	"small": `pieChart-module__small--C5MkR`,
	"large": `pieChart-module__large--sa9NR`
};
export default ___CSS_LOADER_EXPORT___;
