import { ColorPalette } from '../../types'

export const DOT_RADIUS = 6
export const DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES = 8

export const DEFAULT_CHART_OPTIONS = {
  axisStrokeColor: '#757575',
  isAnimationActive: false,
} as const

export const COLOR_PALETTES = {
  [ColorPalette.CATEGORICAL]: [
    '#300080',
    '#0034E3',
    '#1190FF',
    '#007D48',
    '#1EAA52',
    '#FCA600',
    '#FF5000',
    '#A50767',
  ],
  [ColorPalette.SEQUENTIAL]: [
    '#020664',
    '#02068E',
    '#061DBB',
    '#0034E3',
    '#1151FF',
    '#4CABFF',
    '#87CEFF',
  ],
} as const

export enum CHART_TYPES {
  AreaChart = 'AreaChart',
  BarChart = 'BarChart',
  LineChart = 'LineChart',
  PieChart = 'PieChart',
  RadarChart = 'RadarChart',
  StackedBarChart = 'StackedBarChart',
  StackedAreaChart = 'StackedAreaChart',
  ScatterChart = 'ScatterChart',
  BigNumberChart = 'BigNumberChart',
  ListChart = 'ListChart',
  Table = 'Table',
}

export const CHART_SIZE = {
  small: 'small',
  medium: 'medium',
  large: 'large',
}
