import { DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES } from '../consts'
import styles from './chart.module.styl'

interface CustomTooltipProps {
  isExpanded: boolean
  active?: boolean
  payload?: { dataKey: string; value: string; fill: string; color: string }[]
  label?: string
}

export const CustomTooltip = ({ active, payload, label, isExpanded }: CustomTooltipProps) => {
  if (active && payload?.length) {
    const items = isExpanded ? payload : payload.slice(0, DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES)

    return (
      <div className={styles.customTooltip}>
        <p className={styles.label}>{label}</p>
        {items.map((item, index) => (
          <div key={index} className={styles.item}>
            <div
              className={styles.square}
              style={{
                background: item.color,
              }}
            />{' '}
            <div className={styles.itemText}>
              <span>{item.dataKey}:</span>
              <span>{item.value}</span>
            </div>
          </div>
        ))}
        {!isExpanded && payload?.length > DEFAULT_CHART_TOOLTIP_VISIBLE_VALUES && (
          <div>Expand Widget to see more</div>
        )}
      </div>
    )
  }

  return null
}
