// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scatterChart-module__chart--E6yVy{font-size:10px}.scatterChart-module__chart--E6yVy.scatterChart-module__small--MjW6N{font-size:10px}.scatterChart-module__chart--E6yVy.scatterChart-module__large--UWj7k{font-size:14px}`, "",{"version":3,"sources":["webpack://./src/components/Charts/ScatterChart/scatterChart.module.styl"],"names":[],"mappings":"AAAA,mCACE,cAAU,CACV,qEACE,cAAU,CACZ,qEACE,cAAU","sourcesContent":[".chart\n  font-size 10px\n  &.small\n    font-size 10px\n  &.large\n    font-size 14px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chart": `scatterChart-module__chart--E6yVy`,
	"small": `scatterChart-module__small--MjW6N`,
	"large": `scatterChart-module__large--UWj7k`
};
export default ___CSS_LOADER_EXPORT___;
