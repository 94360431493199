import styles from '../../widget.module.styl'
import { CustomFields } from './types'
import { CUSTOM_FIELDS } from './utils'
import { TextField } from '@nike/eds'
import { Control, Controller, useFieldArray } from 'react-hook-form'

interface RequiredSupportedParams {
  control: Control<CustomFields>
}

export const RequiredSupportedParams = ({ control }: RequiredSupportedParams) => {
  const { fields } = useFieldArray<CustomFields>({
    control,
    name: CUSTOM_FIELDS.supportedRequiredParams,
  })

  return (
    <div className={styles.queryParamsContent} data-testid='query-parameters-required'>
      {fields?.map((item, index) => (
        <div className={styles.queryParamsRow} key={item.id}>
          <Controller
            name={`${CUSTOM_FIELDS.supportedRequiredParams}.${index}.parameter`}
            control={control}
            render={({ field }) => (
              <TextField
                id={`${CUSTOM_FIELDS.supportedRequiredParams}.${index}.parameter`}
                label='Parameter'
                hideLabel
                data-testid='query-parameter'
                disabled
                {...field}
              />
            )}
          />
          <Controller
            name={`${CUSTOM_FIELDS.supportedRequiredParams}.${index}.value`}
            control={control}
            render={({ field }) => (
              <TextField
                id={`${CUSTOM_FIELDS.supportedRequiredParams}.${index}.value`}
                label='value'
                hideLabel
                data-testid='query-parameter-value'
                {...field}
              />
            )}
          />
          <div />
        </div>
      ))}
    </div>
  )
}
