import { ChartData } from '../cx-widget'

interface ChartCache {
  ttl: number
  data: ChartData
}

export const widgetCacheKey = (id: string) => `widget_cache_${id}`

export const getDataFromCache = (id?: string) => {
  if (id) {
    const cacheItemRaw = window.localStorage.getItem(widgetCacheKey(id))
    if (cacheItemRaw) {
      const cacheItemParsed: ChartCache = JSON.parse(cacheItemRaw)

      if (!cacheItemParsed.ttl || Date.now() > cacheItemParsed.ttl) {
        // return cached data before refetch
        return { data: cacheItemParsed.data, shouldFetch: true }
      }

      return { data: cacheItemParsed.data, shouldFetch: false }
    }
  }

  return { data: undefined, shouldFetch: true }
}

export const setWidgetCache = (data: ChartData, id?: string, refreshFrequency?: number) => {
  if (id && refreshFrequency) {
    const cache: ChartCache = {
      data,
      ttl: Date.now() + refreshFrequency * 1000,
    }
    window.localStorage.setItem(widgetCacheKey(id), JSON.stringify(cache))
  }
}
