import type { ChartResolverProps } from '../ChartResolver'
import { CustomResponsiveContainer } from '../CustomResponsiveContainer'
import { CHART_SIZE } from '../consts'
import { getChartKeys, getYAxisDomain } from '../utils'
import styles from './radarChart.module.styl'
import { renderRadar } from './radarRenderer'
import classnames from 'classnames'
import {
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  RadarChart as RadarChartRecharts,
  Tooltip,
} from 'recharts'

export const RadarChart = ({
  additionalClassName = '',
  size,
  data,
  options,
  height,
  colorPalette,
  isExpanded = false,
  ...props
}: ChartResolverProps): JSX.Element | null => {
  if (!data || Object.keys(data).length === 0) {
    return null
  }

  const keys = getChartKeys(data, ['drillDownUrl'])

  return (
    <div
      className={classnames(styles.chart, additionalClassName, {
        [styles.small]: size === CHART_SIZE.small,
        [styles.large]: size === CHART_SIZE.large,
      })}
      style={height ? { height } : {}}
    >
      <CustomResponsiveContainer width='99%' height={height || '99%'}>
        <RadarChartRecharts {...props} data={data} height={height}>
          <PolarGrid />
          <PolarAngleAxis dataKey='name' />
          <PolarRadiusAxis domain={getYAxisDomain(options?.axisYLimit)} allowDataOverflow />
          <Tooltip offset={20} cursor={{ stroke: 'red', strokeWidth: 2 }} />
          {isExpanded && options?.legend && (
            <Legend key='legend' verticalAlign='bottom' align='center' height={50} />
          )}
          {renderRadar(keys, colorPalette)}
        </RadarChartRecharts>
      </CustomResponsiveContainer>
    </div>
  )
}
