import { SupportedParam, Option } from '../../types'
import styles from '../../widget.module.styl'
import { CustomFields } from './types'
import { CUSTOM_FIELDS, generateQueryParamsOptions } from './utils'
import { Button, IconButton, Select, SingleValue, TextField } from '@nike/eds'
import { useEffect } from 'react'
import { Controller, Control, useFieldArray, useWatch } from 'react-hook-form'

interface OptionalSupportedParamsParams {
  control: Control<CustomFields>
  supportedParams: SupportedParam[]
}

const DEFAULT_QUERY_PARAM = { parameter: '', value: '' }

export const OptionalSupportedParams = ({
  control,
  supportedParams,
}: OptionalSupportedParamsParams) => {
  const { fields, append, remove } = useFieldArray<CustomFields>({
    control,
    name: CUSTOM_FIELDS.queryParams,
  })
  const watchedParams = useWatch({ name: CUSTOM_FIELDS.queryParams, control })
  const requiredParams = supportedParams.filter(({ isRequired }) => !!isRequired)
  const options = generateQueryParamsOptions(
    supportedParams.filter(({ isRequired }) => !isRequired)
  )

  useEffect(() => {
    if (requiredParams.length < 1) {
      append(DEFAULT_QUERY_PARAM)
    }
  }, [append, requiredParams.length])

  return (
    <div className={styles.queryParamsContent} data-testid='query-parameters-required'>
      {fields?.map((item, index) => (
        <div className={styles.queryParamsRow} key={item.id}>
          <Controller
            name={`${CUSTOM_FIELDS.queryParams}.${index}.parameter`}
            control={control}
            render={({ field: { onBlur, onChange, value, ref }, fieldState: { error } }) => (
              <Select
                id={`${CUSTOM_FIELDS.queryParams}.${index}.parameter`}
                ref={ref}
                label='Parameter'
                hideLabel
                options={options}
                onBlur={onBlur}
                isMulti={false}
                isCreatable
                data-testid='query-parameter'
                placeholder='Select parameter or create new...'
                menuPosition='fixed'
                value={
                  options.find((option) => option.value === value) || value !== ''
                    ? { label: value, value }
                    : null
                }
                onChange={(selectedOption: SingleValue<Option<string>>) => {
                  onChange(selectedOption?.value)
                }}
                errorMessage={error?.message}
                hasErrors={!!error}
              />
            )}
          />
          <Controller
            name={`${CUSTOM_FIELDS.queryParams}.${index}.value`}
            control={control}
            render={({ field }) => {
              const sampleValue = supportedParams?.find(
                (param) => param.parameter === watchedParams[index]?.parameter
              )?.sampleValue
              return (
                <TextField
                  id={`${CUSTOM_FIELDS.queryParams}.${index}.value`}
                  label='value'
                  hideLabel
                  data-testid='query-parameter-value'
                  message={sampleValue ? `Sample value: ${sampleValue}` : ''}
                  {...field}
                />
              )
            }}
          />
          <div className={styles.removeItemButton}>
            <IconButton
              size='small'
              icon='Close'
              label='Delete'
              onClick={() => {
                remove(index)
              }}
              disabled={fields.length <= 1}
              data-testid='remove-parameter'
            />
          </div>
        </div>
      ))}
      <div className={styles.addItemButtonWrapper}>
        <Button variant='secondary' onClick={() => append(DEFAULT_QUERY_PARAM)}>
          Add More
        </Button>
      </div>
    </div>
  )
}
