import { ChartData } from '../../components/Charts/types'
import { getConfig } from '../../config'
import { isChartData, labels } from '../../cx-widget'
import { setWidgetCache } from '../../utils/chart-cache'
import { parseQueryParam } from '../../utils/query-param-parser'
import { DataStruct, parseSignalFxData } from './parse-data'
import { Dispatch, SetStateAction } from 'react'
import { SignalFlow, SignalFlowClientOptions, SignalFlowClient } from 'signalflow'

type ApiHandler = (options: {
  accessToken: string
  setWidgetData: Dispatch<
    SetStateAction<
      | {
          data: ChartData | undefined
          shouldFetch: boolean
        }
      | undefined
    >
  >
  setErrorMessage: Dispatch<SetStateAction<string>>
  setIsFetching: Dispatch<SetStateAction<boolean>>
  dataSourceURL: string
  refreshFrequency?: number
  id?: string
}) => Promise<unknown>

const DEFAULT_CONFIG = {
  startDate: Date.now() - 1000 * 60 * 20,
  endDate: Date.now(),
  resolution: 120000,
}

export const signalFxHandler: ApiHandler = async ({
  dataSourceURL,
  refreshFrequency,
  id,
  setErrorMessage,
  setIsFetching,
  setWidgetData,
}) => {
  try {
    // more info https://jira.nike.com/browse/CX-6622
    const temporaryToken = localStorage.getItem('SIGNAL_FX_TOKEN') || ''
    const config = getConfig()
    if (!dataSourceURL.includes('?')) {
      setErrorMessage(labels.notValidURL)
      return
    }
    const urlParams = new URLSearchParams(dataSourceURL.split('?')[1])
    const chartId = urlParams.get('fromChart')
    const fetchStructure = await fetch(`${config.signalFx.apiUrl}/v2/chart/${chartId}`, {
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'X-SF-Token': temporaryToken,
      },
      method: 'GET',
      body: undefined,
    })
    const structureData = await fetchStructure.json()

    if ([400, 401, 403].includes(fetchStructure.status)) {
      setErrorMessage(labels.notValidWidgetData)
      setIsFetching(false)

      return
    }
    const webSocketErrorCallback = () => {
      setErrorMessage('Signal fx connection error')
    }
    const options: SignalFlowClientOptions = {
      signalflowEndpoint: config.signalFx.wsUrl,
      webSocketErrorCallback: webSocketErrorCallback,
    }

    // @ts-expect-error
    const client: SignalFlowClient = new SignalFlow(temporaryToken, options)

    // https://github.com/signalfx/signalflow-client-js
    const websocketHandler = client.execute({
      program: structureData.programText,
      start: parseInt(parseQueryParam(urlParams.get('start'), DEFAULT_CONFIG.startDate)),
      stop: parseInt(parseQueryParam(urlParams.get('stop'), DEFAULT_CONFIG.endDate)),
      resolution: parseInt(urlParams.get('resolution') || '') || DEFAULT_CONFIG.resolution,
      immediate: true,
    })

    const currentData: DataStruct = {}

    websocketHandler.stream(async (error, data) => {
      if (!data) {
        return
      }
      if (error?.message) {
        setErrorMessage(error?.message)
      }

      switch (data?.type) {
        case 'data':
          if (data?.data) {
            currentData[data.logicalTimestampMs] = data.data
          }
          break
        case 'control-message':
          if (data.event === 'END_OF_CHANNEL') {
            let widgetData: ChartData | undefined = undefined
            try {
              widgetData = await parseSignalFxData(currentData, structureData, websocketHandler)
            } catch (error) {
              console.error(error)
            }

            if (isChartData(widgetData)) {
              setWidgetData({ data: widgetData, shouldFetch: false })
              setWidgetCache(widgetData, id, refreshFrequency)
            } else {
              setErrorMessage(labels.notValidWidgetData)
            }

            setIsFetching(false)
          }
          break
      }
    })
  } catch (error) {
    console.error(error)

    setErrorMessage(labels.notValidWidgetData)
    setIsFetching(false)
  }
}
