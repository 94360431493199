import { ChartResolverProps } from './ChartResolver'
import styles from './customResponsiveContainer.module.styl'
import { ResponsiveContainer } from 'recharts'

interface CustomResponsiveContainerProps
  extends Omit<ChartResolverProps, 'children' | 'height' | 'width' | 'type' | 'colorPalette'> {
  children: JSX.Element
  height: string | number
  width: string | number
}

// from https://github.com/recharts/recharts/issues/1767#issuecomment-598607012
export const CustomResponsiveContainer = (props: CustomResponsiveContainerProps) => (
  <div className={styles.customResponsiveWrapper}>
    <div className={styles.customResponsiveInner}>
      <ResponsiveContainer {...props} />
    </div>
  </div>
)
