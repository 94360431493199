export type QueryName = keyof typeof QUERIES

export const QUERIES_NAMES: { [P in QueryName]: P } = {
  githubWidgetData: 'githubWidgetData',
}

export const QUERIES = {
  githubWidgetData: `
  query githubWidgetData($dataSourceURL: String!) {
    githubWidgetData(dataSourceURL: $dataSourceURL) {
      data
    }
  }
`,
}
