// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts-module__scrollable--BNoyE{overflow-y:auto}.charts-module__scrollable--BNoyE::-webkit-scrollbar{width:.5em}.charts-module__scrollable--BNoyE::-webkit-scrollbar-track{background:var(--eds-color-grey-4)}.charts-module__scrollable--BNoyE::-webkit-scrollbar-thumb{background:var(--eds-color-grey-1);border-radius:var(--eds-radii-4)}.charts-module__scrollable--BNoyE::-webkit-scrollbar-thumb:hover{background:var(--eds-color-grey-2)}`, "",{"version":3,"sources":["webpack://./src/components/Charts/charts.module.styl"],"names":[],"mappings":"AAAA,kCACE,eAAW,CAEX,qDACE,UAAM,CAER,2DACE,kCAAW,CAEb,2DACE,kCAAW,CACX,gCAAc,CAEhB,iEACE,kCAAW","sourcesContent":[".scrollable\n  overflow-y auto\n\n  &::-webkit-scrollbar   \n    width 0.5em\n\n  &::-webkit-scrollbar-track\n    background var(--eds-color-grey-4)\n\n  &::-webkit-scrollbar-thumb\n    background var(--eds-color-grey-1)\n    border-radius var(--eds-radii-4)\n\n  &::-webkit-scrollbar-thumb:hover \n    background var(--eds-color-grey-2)\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"scrollable": `charts-module__scrollable--BNoyE`
};
export default ___CSS_LOADER_EXPORT___;
