import { labels } from '../../constants'
import { WidgetSize } from '../../types'
import styles from '../../widget.module.styl'
import { Collapsible } from '../Collapsible'
import { SizeIcon } from './SizeIcon'
import { Spinner, Text } from '@nike/eds'

interface ResizeWidgetProps {
  currentSize?: WidgetSize
  isUpdatingSize?: boolean
  handleResizeWidget?: (size: WidgetSize) => void
  toggleMenu?: () => void
  id?: string
}

const WIDGET_SIZES = Object.values(WidgetSize)

export const ResizeWidget = ({
  currentSize,
  handleResizeWidget,
  isUpdatingSize,
  toggleMenu,
  id,
}: ResizeWidgetProps) => (
  <div className={isUpdatingSize ? styles.disabled : ''}>
    <Collapsible
      title={<Text font='body-3'>{labels.menu.resize}</Text>}
      innerClassName={styles.sizesContainer}
      transitionTime={30}
      className={styles.resize}
      id={id}
    >
      <div className={styles.sizeButtons}>
        {isUpdatingSize ? (
          <Spinner data-testid='resize-spinner' />
        ) : (
          WIDGET_SIZES.map(
            (size) =>
              size !== currentSize && (
                <button
                  key={size}
                  onClick={() => {
                    handleResizeWidget?.(size)
                    toggleMenu && toggleMenu()
                  }}
                  name={size}
                  data-testid={`size-button-${size}`}
                >
                  <SizeIcon size={size} />
                </button>
              )
          )
        )}
      </div>
    </Collapsible>
  </div>
)
