import { ColorPalette } from '../../../types'
import { DEFAULT_CHART_OPTIONS, DOT_RADIUS } from '../consts'
import type { DataItem } from '../types'
import { getColor, openInNewTab } from '../utils'
import { Area, Bar, Cell, Line } from 'recharts'

export const renderAreas = (keys: string[], areaType: string, colorPalette: ColorPalette) =>
  keys.map((key: string, index: number) => (
    <Area
      type='monotone'
      key={key}
      dataKey={key}
      {...(areaType === 'stacked' ? { stackId: '1' } : {})}
      stroke={getColor(index, colorPalette)}
      fill={getColor(index, colorPalette)}
      isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
      activeDot={{
        r: DOT_RADIUS,
        onClick: (_event, payload) =>
          // eslint-disable-next-line
          (payload as any)?.payload?.drillDownUrl && // need to be any here
          // eslint-disable-next-line
          openInNewTab((payload as any).payload.drillDownUrl), // need to be any here
      }}
    />
  ))

export const renderBars = (
  keys: string[],
  barType: string,
  colorPalette: ColorPalette,
  data?: DataItem[]
) =>
  keys.map((key: string, index: number) => (
    <Bar
      key={key}
      dataKey={key}
      {...(barType === 'stacked' ? { stackId: '1' } : {})}
      fill={getColor(index, colorPalette)}
      isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
    >
      {data?.map((entry) => (
        <Cell
          key={`cell-${index}`}
          onClick={() => typeof entry.drillDownUrl === 'string' && openInNewTab(entry.drillDownUrl)}
        />
      ))}
    </Bar>
  ))

export const renderLines = (keys: string[], colorPalette: ColorPalette) =>
  keys.map((key: string, index: number) => (
    <Line
      key={key}
      strokeWidth={2}
      type='monotone'
      dataKey={key}
      stroke={getColor(index, colorPalette)}
      isAnimationActive={DEFAULT_CHART_OPTIONS.isAnimationActive}
      activeDot={{
        r: DOT_RADIUS,
        onClick: (_event, payload) =>
          // eslint-disable-next-line
          (payload as any)?.payload?.drillDownUrl && // need to be any here
          // eslint-disable-next-line
          openInNewTab((payload as any).payload.drillDownUrl), // need to be any here
      }}
    />
  ))
