import { WidgetSize } from '../../types'
import styles from './sizeIcon.module.styl'
import classnames from 'classnames'

interface SizeIconProps {
  size: WidgetSize
}

export const SizeIcon = ({ size }: SizeIconProps) => (
  <div
    className={classnames(styles.wrapper, {
      [styles.verticalRect]: size === WidgetSize.VERTICALRECT,
      [styles.horizontalRect]: size === WidgetSize.HORIZONTALRECT,
      [styles.smallSquare]: size === WidgetSize.SMALLSQUARE,
      [styles.bigSquare]: size === WidgetSize.BIGSQUARE,
    })}
  >
    {[...Array(4).keys()].map((key) => (
      <div key={key}></div>
    ))}
  </div>
)
