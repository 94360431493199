import { ColorPalette } from '../../types'
import { COLOR_PALETTES } from './consts'
import type { AxisLimit, ChartData, DataItem, Must } from './types'

// need to be any here
// eslint-disable-next-line
export const isChartData = (data?: any): data is ChartData =>
  typeof data?.value?.type === 'string' && Array.isArray(data?.value?.data)

export const getColor = (index: number, colorPalette: ColorPalette) =>
  COLOR_PALETTES[colorPalette][index % COLOR_PALETTES[colorPalette].length]

/**
 * getChartKeys returns a list of keys that are to be used for plotting the chart.
 * The function excludes "name" as it will be used for labels.  A person can also use
 * the chart to obtain specific key values if present.
 *
 * @param {*} data - array of data objects
 * @param {*} exclude - list of keys to EXCLUDE from the returned list
 */
export const getChartKeys = <T extends object>(data: T[], excludeKeys?: string[]): string[] =>
  data.reduce((keys: string[], current: T) => {
    const currentKeys = [...keys]
    if (keys === null) keys = []
    for (const key in current) {
      if (
        key.toLowerCase() !== 'name' &&
        // @ts-ignore
        Object.hasOwn(current, key) &&
        !currentKeys.some((k) => k === key)
      ) {
        if (!excludeKeys || !excludeKeys.some((k) => k === key)) {
          currentKeys.push(key)
        }
      }
    }
    return [...currentKeys]
  }, [])
/**
 * Determines if a variable is a 2D array.
 *
 * @param {*} data - value to check
 * @returns boolean
 */
export const is2DArray = <T>(data: T[]): boolean => !!(data?.length && Array.isArray(data[0]))

export const openInNewTab = (url: string | number) => {
  window.open(url.toString(), '_blank')
}

export const removeNaNValuesFromArray = <T extends object | object[]>(
  data: T[],
  includeKeys?: string[]
): T[] | T[][] => {
  if (data && Array.isArray(data[0])) {
    return (data as T[][]).map((value: T[]) => removeNaNValues(value, includeKeys))
  } else {
    return removeNaNValues(data, includeKeys)
  }
}

export const removeNaNValues = <T extends object>(data: T[], includeKeys?: string[]): T[] => {
  const nonNumericFields = new Set<string>()

  data.forEach((obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      if (
        key.toLowerCase() !== 'name' &&
        isNaN(Number(value)) &&
        !includeKeys?.some((k) => k === key)
      ) {
        nonNumericFields.add(key)
      }
    })
  })

  return data.map((obj: T) => {
    const newObj: any = { ...obj }
    nonNumericFields.forEach((field: string) => delete newObj[field])
    return newObj
  })
}

export const isAxisYLimitDefined = (axisYLimit?: AxisLimit): axisYLimit is Must<AxisLimit> =>
  !!axisYLimit && typeof axisYLimit.min === 'number' && typeof axisYLimit.max === 'number'

export const getYAxisDomain = (axisYLimit?: AxisLimit): [number, number] | undefined =>
  isAxisYLimitDefined(axisYLimit) ? [axisYLimit.min, axisYLimit.max] : undefined

export const areAllValuesZero = (data: DataItem[]): boolean =>
  data.flat().reduce((acc, item) => {
    let sum = 0
    // Sum all numeric values in item, since it's not necessarily 'value'
    for (const key in item) {
      const value = item[key]
      if (typeof value === 'number') {
        sum += value
      }
    }
    return acc + sum
  }, 0) === 0
