import { getConfig } from '../../config'
import { ColorPalette, Widget } from '../../types'
import { useGetWidgetData } from '../../utils/useGetWidgetData'
import styles from '../../widget.module.styl'
import { ChartOptions, ChartResolver } from '../Charts'
import { WidgetMessage } from '../WidgetMessage/WidgetMessage'
import { WidgetModalProps } from '../WidgetModal/WidgetModal'
import { OptionalSupportedParams } from './OptionalSupportedParams'
import { RequiredSupportedParams } from './RequiredSupportedParams'
import { SaveWidgetPanel } from './SaveWidgetPanel'
import { CustomFields, EditQueryProps } from './types'
import {
  copyURL,
  createSourceURLWithParams,
  CUSTOM_FIELDS,
  mapInboundDataToFormValues,
} from './utils'
import { Button, ButtonGroup, Icon, SidePanel, Spinner, Text, TextField } from '@nike/eds'
import { useState } from 'react'
import { createPortal } from 'react-dom'
import { useForm, useWatch } from 'react-hook-form'

type WidgetSidePanelProps<T> = WidgetModalProps<T> & {
  accessToken: string
  chartOptions: ChartOptions
  editQueryProps: EditQueryProps
}

export const WidgetSidePanel = <T extends Widget>({
  closeModal,
  widget,
  chartOptions,
  createSnack,
  editQueryProps: { handleSaveWidget, accessToken },
}: WidgetSidePanelProps<T>) => {
  const { env } = getConfig()
  const { dataSourceURL, id, name, timezoneSupport, customWidgetId, supportedParams } = widget

  const { control, handleSubmit } = useForm<CustomFields>({
    defaultValues: mapInboundDataToFormValues(widget),
  })

  const watchedParams = useWatch({
    name: [CUSTOM_FIELDS.supportedRequiredParams, CUSTOM_FIELDS.queryParams],
    control,
  })
  const pureSourceURL = dataSourceURL?.split('?')[0]
  const updatedSourceURL = createSourceURLWithParams(watchedParams.flat(1), pureSourceURL)
  const [isSaveWidgetPanelOpen, setIsSaveWidgetPanelOpen] = useState(false)
  const updatedName = name
  const {
    get,
    isFetching,
    errorMessage,
    data: widgetData,
  } = useGetWidgetData({
    accessToken,
    env,
    id: id || name,
    customWidgetId,
    widget: { ...widget, timezoneSupport: timezoneSupport || false },
  })

  const currentWidgetConfig = {
    ...widget,
    dataSourceURL: updatedSourceURL,
    name: updatedName,
  }

  const save = () => {
    handleSaveWidget(currentWidgetConfig, watchedParams.flat(1))
    closeModal()
  }
  const saveAsNew = () => {
    const shouldBeSavedAsNew = true
    handleSubmit((data) => {
      handleSaveWidget(
        { ...currentWidgetConfig, name: data.name },
        data.queryParams,
        shouldBeSavedAsNew
      )
    })()
    setIsSaveWidgetPanelOpen(false)
    closeModal()
  }

  const saveAsExisting = () => {
    handleSubmit((data) => {
      handleSaveWidget({ ...currentWidgetConfig, name: data.name }, data.queryParams)
    })()
    setIsSaveWidgetPanelOpen(false)
    closeModal()
  }

  return (
    <>
      <SidePanel
        data-testid='widget-side-panel'
        id='widget-side-panel'
        hasScrim
        isOpen
        displayCheckOverride={process.env.NODE_ENV === 'test'}
        onDismiss={closeModal}
        className={styles.widgetDetailsSidePanel}
        headerSlot={widget.name}
        footerSlot={
          <ButtonGroup>
            <Button size='small' onClick={closeModal}>
              Close
            </Button>
            <Button size='small' variant='secondary' onClick={() => setIsSaveWidgetPanelOpen(true)}>
              Save as...
            </Button>
            <Button size='small' variant='secondary' onClick={save}>
              Save
            </Button>
          </ButtonGroup>
        }
        hideFade
      >
        <div className={styles.widgetDetailsSidePanelSection}>
          <div className={styles.widgetDetailsSidePanelChart}>
            {isFetching && (
              <div className={styles.spinner}>
                <Spinner role='progressbar' />
              </div>
            )}
            {errorMessage && <WidgetMessage text={errorMessage} />}
            {widgetData?.value?.data && (
              <ChartResolver
                type={widget.chartType || widgetData.value.type}
                data={widgetData.value?.data}
                columns={widgetData.value?.columns}
                responsiveHeight='100%'
                additionalClassName={styles.chart}
                size='large'
                isExpanded
                colorPalette={widget?.colorPalette || ColorPalette.CATEGORICAL}
                options={chartOptions}
              />
            )}
          </div>
          <div className={styles.dataSourceURLWrapper}>
            <Text font='title-6' className={styles.dataSourceURLTitle}>
              Data Source URL
            </Text>
            <div className={styles.dataSourceURLRow}>
              <TextField value={updatedSourceURL} id='url' label='url' hideLabel disabled />
              <Button
                onClick={async () => {
                  await get?.(updatedSourceURL)
                }}
                data-testid='run-query'
              >
                Run
              </Button>
              <Button
                size='small'
                variant='secondary'
                beforeSlot={<Icon name='Link' />}
                onClick={() => copyURL(updatedSourceURL, createSnack)}
              >
                Copy Link
              </Button>
            </div>
          </div>
          <div className={styles.queryParamsWrapper}>
            <Text font='title-6' className={styles.queryParamsTitle}>
              Query Parameters
            </Text>
            <div className={styles.queryParamsContent} data-testid='query-parameters'>
              <RequiredSupportedParams control={control} />
              <OptionalSupportedParams control={control} supportedParams={supportedParams || []} />
            </div>
          </div>
        </div>
      </SidePanel>
      {isSaveWidgetPanelOpen &&
        createPortal(
          <SaveWidgetPanel
            closeSidePanel={() => {
              setIsSaveWidgetPanelOpen(false)
            }}
            saveAsNew={saveAsNew}
            saveAsExisting={saveAsExisting}
            control={control}
          />,
          document.getElementById('portal') || document.body
        )}
    </>
  )
}
