import type { ChartResolverProps } from '../ChartResolver'
import { CustomResponsiveContainer } from '../CustomResponsiveContainer'
import { CHART_SIZE, DEFAULT_CHART_OPTIONS } from '../consts'
import { getYAxisDomain } from '../utils'
import styles from './scatterChart.module.styl'
import { renderScatter } from './scatterRenderer'
import classnames from 'classnames'
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  ScatterChart as ScatterChartRecharts,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

export const ScatterChart = ({
  height,
  additionalClassName = '',
  options,
  size,
  colorPalette,
  isExpanded = false,
  ...props
}: ChartResolverProps): JSX.Element | null => {
  if (!props.data || Object.keys(props.data).length === 0) {
    return null
  }

  return (
    <div
      className={classnames(styles.chart, additionalClassName, {
        [styles.small]: size === CHART_SIZE.small,
        [styles.large]: size === CHART_SIZE.large,
      })}
      style={height ? { height } : {}}
    >
      <CustomResponsiveContainer width='99%' height={height || '99%'}>
        <ScatterChartRecharts>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis
            dataKey='x'
            type='number'
            name={options?.xAxisLabel}
            unit={options?.xAxisUnit}
            height={46}
            stroke={DEFAULT_CHART_OPTIONS.axisStrokeColor}
          >
            <Label
              value={options?.xAxisLabel}
              position='insideTopRight'
              fontWeight={600}
              dx={5}
              dy={18}
            />
          </XAxis>
          <YAxis
            dataKey='y'
            type='number'
            name={options?.yAxisLabel}
            unit={options?.yAxisUnit}
            stroke={DEFAULT_CHART_OPTIONS.axisStrokeColor}
            domain={getYAxisDomain(options?.axisYLimit)}
            allowDataOverflow
          >
            <Label value={options?.yAxisLabel} angle={-90} position='insideLeft' fontWeight={600} />
          </YAxis>
          <Tooltip />
          {isExpanded && options?.legend && (
            <Legend
              key='legend'
              verticalAlign='bottom'
              align='center'
              height={50}
              wrapperStyle={{ paddingTop: '12px' }}
            />
          )}
          {isExpanded && options?.withBrush && <Brush key='brush' height={20} dy={0} />}
          {renderScatter(colorPalette, options?.categories || [], props.data)}
        </ScatterChartRecharts>
      </CustomResponsiveContainer>
    </div>
  )
}
