import { ChartData, DataItem } from '../../cx-widget'
import { format } from 'date-fns'
import { StreamMetadataMessage, StreamDataPoint, Stream } from 'signalflow'

export interface DataStruct {
  [key: string]: StreamDataPoint[]
}

interface ChartStructure {
  name: string
  options: {
    legendOptions: {
      fields: {
        enabled: boolean
        property: string
      }[]
    }
    publishLabelOptions: {
      displayName: string
      label: string
      yAxis: number
    }[]
    axes: {
      label: string
    }[]
    axisPrecision: number
    maximumPrecision?: number
    defaultPlotType: string
    type: string
  }
}

const round = (value: number, structureData?: ChartStructure) => {
  const axisPrecision =
    structureData?.options.axisPrecision || structureData?.options?.maximumPrecision
  return axisPrecision ? value.toFixed(axisPrecision) : value
}
const getLabels = (structureData: ChartStructure) =>
  structureData.options?.legendOptions?.fields
    ?.filter((label) => label.enabled && label.property !== 'sf_metric')
    ?.map((label) => label.property) || []

const getCustomLabel = (structureData: ChartStructure, metadata: StreamMetadataMessage) =>
  structureData?.options?.publishLabelOptions?.find(
    (publishedLabel) => publishedLabel.label === metadata.properties.sf_streamLabel
  )

const parseListChart = async (data: DataStruct, structureData: ChartStructure, stream: Stream) => {
  const keys: string[] = Object.keys(data)
  const element = data[keys[keys.length - 1]]

  const labels = getLabels(structureData)

  const widgetData: ChartData = {
    value: {
      type: 'ListChart',
      data: element.map((item) => {
        const value = stream.get_metadata(item.tsId)
        const label = labels.map((property) => value.properties[property]).join(' - ')
        return {
          name: `${round(item.value, structureData)} - ${label}`,
        }
      }),
    },
  }

  return widgetData
}

const parseTableChart = async (data: DataStruct, structureData: ChartStructure, stream: Stream) => {
  const keys = Object.keys(data)
  const element = data[keys[keys.length - 1]]

  const columns = structureData.options.legendOptions.fields.map((field) => ({
    name: field.property,
  }))

  const valueColumn = structureData.options.publishLabelOptions[0].displayName

  columns.push({
    name: valueColumn,
  })
  const widgetData: ChartData = {
    value: {
      type: 'Table',
      columns: columns,
      data: element.map((item) => {
        const result: { [key: string]: string } = {}
        columns.forEach((column) => {
          result[column.name] = stream.get_metadata(item.tsId).properties[column.name]
        })

        result[valueColumn] = round(item.value, structureData).toString()

        return result
      }),
    },
  }

  return widgetData
}
const parseSingleValueChart = async (data: DataStruct, structureData: ChartStructure) => {
  const element = data[Object.keys(data)[0]][0]

  const widgetData: ChartData = {
    value: {
      type: 'BigNumberChart',
      data: [
        {
          number: round(element.value, structureData),
          unit: structureData.name,
          additionalInformation: format(parseInt(Object.keys(data)[0]), 'yyyy-MM-dd HH:mm:ss'),
        },
      ],
    },
  }

  return widgetData
}

const parseAreaChart = async (data: DataStruct, structureData: ChartStructure, stream: Stream) => {
  const widgetData: ChartData = {
    value: {
      type: 'AreaChart',
      data: [],
    },
    yAxisLabel: structureData.options.axes[0].label,
    xAxisLabel: 'Time',
  }

  const labels = getLabels(structureData)
  let index = 0
  for (const timeStamp in data) {
    const element = data[timeStamp]
    element.forEach((element) => {
      const res = stream.get_metadata(element.tsId)
      const label = labels.map((property) => res.properties[property]).join(' - ')

      const columnData: DataItem = {
        ...(widgetData.value.data[index] || {}),
        name: format(parseInt(timeStamp), 'yyyy-MM-dd HH:mm:ss'),
      }

      if (label) {
        columnData[label] = round(element.value, structureData)
      }

      widgetData.value.data[index] = columnData
    })

    index++
  }

  return widgetData
}

const parseColumnChart = async (
  data: DataStruct,
  structureData: ChartStructure,
  stream: Stream
) => {
  const widgetData: ChartData = {
    value: {
      type: 'BarChart',
      data: [],
    },
    yAxisLabel: structureData.options.axes[0].label,
    xAxisLabel: 'Time',
  }

  const labels = getLabels(structureData)

  let index = 0
  for (const timeStamp in data) {
    const element = data[timeStamp]
    element.forEach((element) => {
      const res = stream.get_metadata(element.tsId)
      const label = labels.map((property) => res.properties[property]).join(' - ')

      const columnData: DataItem = {
        ...(widgetData.value.data[index] || {}),
        name: format(parseInt(timeStamp), 'yyyy-MM-dd HH:mm:ss'),
      }

      if (label) {
        columnData[label] = round(element.value, structureData)
      }

      if (structureData.options.publishLabelOptions.length) {
        const currentLabel = getCustomLabel(structureData, res)
        if (currentLabel) {
          const name =
            structureData.options?.axes?.[currentLabel.yAxis]?.label || currentLabel.displayName
          columnData[name] = round(element.value, structureData)
        }
      }
      widgetData.value.data[index] = columnData
    })

    index++
  }

  return widgetData
}
export const parseSignalFxData = async (
  data: DataStruct,
  structureData: ChartStructure,
  stream: Stream
): Promise<ChartData | undefined> => {
  switch (structureData.options.type) {
    case 'SingleValue':
      return parseSingleValueChart(data, structureData)
    case 'TimeSeriesChart':
      switch (structureData.options.defaultPlotType) {
        case 'AreaChart':
          return parseAreaChart(data, structureData, stream)
        case 'ColumnChart':
          return parseColumnChart(data, structureData, stream)
      }
      break
    case 'List':
      return parseListChart(data, structureData, stream)
    case 'TableChart':
      return parseTableChart(data, structureData, stream)
  }
}
