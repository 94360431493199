import { About } from './components/About'
import { ChartResolver, ChartData } from './components/Charts'
import { MenuType } from './components/Menu/WidgetMenu'
import { WidgetMenuItemConfig, WidgetMenuResolver } from './components/Menu/WidgetMenuResolver'
import { Tags } from './components/Tags'
import { WidgetMessage } from './components/WidgetMessage/WidgetMessage'
import { WidgetModal } from './components/WidgetModal/WidgetModal'
import { WidgetSidePanel, hasSaveWidgetHandler } from './components/WidgetSidePanel'
import { labels } from './constants'
import { ColorPalette, WidgetLifecycleState, Widget, WidgetSize, SnackOptions } from './types'
import styles from './widget.module.styl'
import { Card, IconButton, Spinner, Tag, Text } from '@nike/eds'
import classnames from 'classnames'
import { useState } from 'react'
import { createPortal } from 'react-dom'

interface WidgetStyles {
  widget?: string
  verticalRect?: string
  horizontalRect?: string
  smallSquare?: string
  bigSquare?: string
}

interface WidgetCardProps<T> {
  widget: T & { isFilteredOut?: boolean }
  id?: string
  widgetMenuConfig?: WidgetMenuItemConfig[]
  isFetching: boolean
  errorMessage: string
  widgetData?: ChartData
  widgetStyles?: WidgetStyles
  isShareable?: boolean
  isResizable?: boolean
  getRefreshedWidgetData?: () => Promise<void>
  createSnack?: (options: SnackOptions) => void
  accessToken?: string
}

export const WidgetCard = <T extends Widget>({
  widget,
  id,
  widgetMenuConfig = [],
  isFetching,
  errorMessage,
  widgetData,
  widgetStyles,
  isShareable = false,
  isResizable = false,
  getRefreshedWidgetData,
  createSnack,
  accessToken = '',
}: WidgetCardProps<T>) => {
  const hasWidgetData = widgetData?.value.data.length
  const [isWidgetDetailsOpen, setIsWidgetDetailsOpen] = useState(false)
  const handleWidgetDetailsModal = () => setIsWidgetDetailsOpen(!isWidgetDetailsOpen)
  const [isEditQueryOpen, setIsEditQueryOpen] = useState(false)
  const widgetSize = isResizable ? widget?.size || WidgetSize.SMALLSQUARE : WidgetSize.SMALLSQUARE
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen)

  const handleRemoveWidget = widgetMenuConfig.find(
    (menuItem) => menuItem.type === MenuType.REMOVE
  )?.onClick

  const chartOptions = {
    xAxisLabel: widget?.axesConfig?.xAxisLabel || widgetData?.xAxisLabel,
    yAxisLabel: widget?.axesConfig?.yAxisLabel || widgetData?.yAxisLabel,
    xAxisUnit: widget?.axesConfig?.xAxisUnit || widgetData?.xAxisUnit,
    yAxisUnit: widget?.axesConfig?.yAxisUnit || widgetData?.yAxisUnit,
    legend: widget?.showChartLegend !== null ? widget.showChartLegend : widgetData?.legend,
    withBrush: widget?.withBrush !== null ? widget.withBrush : widgetData?.withBrush,
    axisYLimit:
      widget?.axesConfig?.axisYLimit?.min !== undefined && widget?.axesConfig?.axisYLimit?.max
        ? widget?.axesConfig?.axisYLimit
        : widgetData?.axisYLimit,
    categories: widgetData?.categories,
  }

  const updatedWidgetMenuConfig = [...widgetMenuConfig]

  // expand
  const expandIndex = widgetMenuConfig.findIndex((menuItem) => menuItem.type === MenuType.EXPAND)
  if (expandIndex >= 0) {
    updatedWidgetMenuConfig[expandIndex].onClick = handleWidgetDetailsModal
  }

  // edit query
  const editQueryIndex = widgetMenuConfig.findIndex(
    (menuItem) => menuItem.type === MenuType.EDIT_QUERY
  )
  if (editQueryIndex >= 0) {
    updatedWidgetMenuConfig[editQueryIndex].onClick = () => setIsEditQueryOpen(true)
  }
  const editQueryProps = updatedWidgetMenuConfig[editQueryIndex]?.contextualComponentProps

  // resize
  const getCardAdditionalStyles = () => {
    const verticalRect = widgetStyles?.verticalRect || styles.verticalRect
    const horizontalRect = widgetStyles?.horizontalRect || styles.horizontalRect
    const smallSquare = widgetStyles?.smallSquare || styles.smallSquare
    const bigSquare = widgetStyles?.bigSquare || styles.bigSquare

    return {
      [verticalRect]: widgetSize === WidgetSize.VERTICALRECT,
      [horizontalRect]: widgetSize === WidgetSize.HORIZONTALRECT,
      [smallSquare]: widgetSize === WidgetSize.SMALLSQUARE,
      [bigSquare]: widgetSize === WidgetSize.BIGSQUARE,
      [styles.isHidden]: widget.isFilteredOut,
    }
  }

  return (
    <Card
      className={classnames(styles.widget, getCardAdditionalStyles())}
      data-testid='widget'
      id={id}
      aria-hidden={widget.isFilteredOut}
    >
      <div className={styles.header}>
        <Text
          font='subtitle-1'
          data-testid='widget-name'
          className={styles.elipsis}
          title={widget.name}
        >
          {widget.name}
        </Text>
        <div className={styles.widgetActions}>
          <About widget={widget} />
          {!!updatedWidgetMenuConfig.length && (
            <WidgetMenuResolver
              isMenuOpen={isMenuOpen}
              toggleMenu={toggleMenu}
              config={updatedWidgetMenuConfig}
              id={widget.id || widget.name}
              createSnack={createSnack}
            />
          )}
        </div>
      </div>
      {widget?.lifecycleState === WidgetLifecycleState.RETIRED ? (
        <div className={styles.overlay}>
          <Text<'div'> as='div' font='body-1'>
            {labels.retiredTitle}
          </Text>
          <Text<'div'> as='div' font='body-3'>
            {labels.retiredSubtitle}
          </Text>
          <IconButton onClick={handleRemoveWidget} icon={'Delete'} label='Remove widget' />
        </div>
      ) : isFetching && !hasWidgetData ? (
        <div className={styles.spinner}>
          <Spinner role='progressbar' />
        </div>
      ) : errorMessage ? (
        <WidgetMessage text={errorMessage} />
      ) : hasWidgetData ? (
        <ChartResolver
          type={widget.chartType || widgetData.value.type}
          data={widgetData.value?.data}
          columns={widgetData.value?.columns}
          additionalClassName={styles.chart}
          colorPalette={widget?.colorPalette || ColorPalette.CATEGORICAL}
          options={chartOptions}
          widgetSize={widgetSize}
          isResponsive
        />
      ) : (
        <WidgetMessage
          text={labels.noDataMsg}
          additionalClassName={styles.notAvailable}
          icon={
            <IconButton
              icon='Repeat'
              label={labels.noDataRetry}
              size='small'
              variant='secondary'
              onClick={() => getRefreshedWidgetData?.()}
            />
          }
        />
      )}
      {widget.customWidgetId && widget?.lifecycleState !== WidgetLifecycleState.RETIRED && (
        <Tags>
          {widget.customWidgetId && (
            <Tag size='small' color='yellow'>
              customized
            </Tag>
          )}
        </Tags>
      )}
      {isWidgetDetailsOpen &&
        createPortal(
          <WidgetModal
            closeModal={handleWidgetDetailsModal}
            widget={widget}
            widgetData={widgetData}
            createSnack={createSnack}
            isShareable={isShareable}
            chartOptions={chartOptions}
          />,
          document.getElementById('portal') || document.body
        )}
      {hasSaveWidgetHandler(editQueryProps) &&
        isEditQueryOpen &&
        createPortal(
          <WidgetSidePanel
            closeModal={() => setIsEditQueryOpen(false)}
            widget={widget}
            widgetData={widgetData}
            createSnack={createSnack}
            accessToken={accessToken}
            chartOptions={chartOptions}
            editQueryProps={editQueryProps}
          />,
          document.getElementById('portal') || document.body
        )}
    </Card>
  )
}
