import { getSampleWidgetData } from './cx/sample-widget/handler'
import { WidgetApisConfig, getWidgetApiHandler as proxy } from './utils'

/**
 * List of all the data APIs
 * @type {Object}
 * pair of widget catalog name and its corresponding data fetching function
 */
export const widgetDataApis: WidgetApisConfig = {
  'sample-widget': getSampleWidgetData,
}

/**
 * Returns the widget data fetcher/handler.
 * Throws error if widget data fetcher/handler not found.
 */
export const getCXWidgetApiHandler = proxy(widgetDataApis)
